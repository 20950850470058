
export function timestamp () {
  return +Date.now()
}

export default function useTimestamp () {
  const ts = ref(timestamp())

  let isActive = true
  const update = () => {
    ts.value = timestamp()
    if (isActive) requestAnimationFrame(() => update())
  }
  onMounted(() => update())
  onUnmounted(() => (isActive = false))

  return ts
}
