import useTimestamp, { timestamp } from '~/composables/useTimestamp'

export default function useActionDelay (callback, delayMs = 10000, options = {}) {
  const {
    immediate = false,
    localStorageKey = null
  } = options

  const now = useTimestamp()
  const value = immediate ? timestamp() : null
  const executedAt = process.client && localStorageKey ? useLocalStorage(localStorageKey, value) : ref(value)
  const delay = computed(() => executedAt.value ? Math.max(0, delayMs - (now.value - executedAt.value)) : 0)
  const isActive = computed(() => delay.value <= 0)

  const action = () => {
    if (!isActive.value) return
    executedAt.value = timestamp()
    callback()
  }

  return {
    action,
    executedAt,
    delay,
    isActive
  }
}
