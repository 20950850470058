function pad (n, amount = 2) {
  return n.toString().padStart(amount, '0')
}

export function formatTimeMs (ms, hours = null) {
  const s = Math.ceil(ms / 1000)
  hours = hours === null ? s >= 3600 : hours
  return hours ? pad(Math.floor(s / 3600)) + ':' : '' +
    pad(Math.floor((s % 3600) / 60), hours ? 2 : 1) +
    ':' + pad(s % 60)
}
